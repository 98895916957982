/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArticleAccessory {
  [key: string]: any;

  accessory?: string;
  amount?: number;
  type?: ArticleAccessory.TypeEnum;
  previewUrl?: string;
  shortDescription?: string;
}
export namespace ArticleAccessory {
  export type TypeEnum =
    | "other"
    | "accessory"
    | "base_product"
    | "followup"
    | "mandatory"
    | "similar"
    | "sparepart"
    | "set_component"
    | "cross_selling"
    | "up_selling"
    | "set";
  export const TypeEnum = {
    other: "other" as TypeEnum,
    accessory: "accessory" as TypeEnum,
    base_product: "base_product" as TypeEnum,
    followup: "followup" as TypeEnum,
    mandatory: "mandatory" as TypeEnum,
    similar: "similar" as TypeEnum,
    sparepart: "sparepart" as TypeEnum,
    set_component: "set_component" as TypeEnum,
    cross_selling: "cross_selling" as TypeEnum,
    up_selling: "up_selling" as TypeEnum,
    set: "set" as TypeEnum,
  };
}
