import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";
import { NotificationService } from "../notification.service";

@Injectable({
  providedIn: "root",
})
export class GrantGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public notificationService: NotificationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const grantRequired = route.data.grantRequired as string;
    const fallback = route.data.fallback as string;
    //const hideError = route.data.showError;
    if (!grantRequired || !this.auth.isGranted(grantRequired)) {
      //if (!hideError) this.notificationService.showError('Keine Berechtigung. (' + grantRequired + ')');
      if (fallback) {
        this.router.navigate([fallback]);
      } else {
        this.router.navigate([""]);
      }

      return false;
    }
    return true;
  }
}
