<nb-card style="min-width: 300px">
    <nb-card-body style="text-align: center">
        <nb-icon icon="checkmark-circle-2-outline" style="font-size:128px;margin-bottom:16px" status="success"></nb-icon>
        <p>{{message}}</p>
    </nb-card-body>
    <nb-card-footer>

        <button nbButton (click)="dialog.close(true)"
                fullWidth appearance="filled" size="medium">
            Ok
        </button>

    </nb-card-footer>
</nb-card>
