import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as Sentry from "@sentry/angular";
import { tap } from "rxjs/operators";

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Sentry.addBreadcrumb({
      category: "HTTP_REQUEST",
      data: {
        url: req.url,
        body: req.body,
      },
    });
    return next.handle(req);
  }
}
