/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { StatusModel } from "../model/statusModel";
import { User } from "../model/user";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class UserService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create company user
   * Create new user for company
   * @param companyId
   * @param user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCompanyUser(companyId: string, user: User, observe?: "body", reportProgress?: boolean): Observable<User>;
  public createCompanyUser(
    companyId: string,
    user: User,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public createCompanyUser(
    companyId: string,
    user: User,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public createCompanyUser(
    companyId: string,
    user: User,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling createCompanyUser.");
    }

    if (user === null || user === undefined) {
      throw new Error("Required parameter user was null or undefined when calling createCompanyUser.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<User>(`${this.basePath}/company/${encodeURIComponent(String(companyId))}/user`, user, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * delete user
   * Delete specified user
   * @param companyId
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCompanyUser(
    companyId: string,
    userId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public deleteCompanyUser(
    companyId: string,
    userId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public deleteCompanyUser(
    companyId: string,
    userId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public deleteCompanyUser(
    companyId: string,
    userId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling deleteCompanyUser.");
    }

    if (userId === null || userId === undefined) {
      throw new Error("Required parameter userId was null or undefined when calling deleteCompanyUser.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<StatusModel>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/user/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get user
   * Returns requested user
   * @param companyId
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyUser(
    companyId: string,
    userId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<User>;
  public getCompanyUser(
    companyId: string,
    userId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public getCompanyUser(
    companyId: string,
    userId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public getCompanyUser(
    companyId: string,
    userId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyUser.");
    }

    if (userId === null || userId === undefined) {
      throw new Error("Required parameter userId was null or undefined when calling getCompanyUser.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<User>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/user/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get list of users
   * Returns a list users linked to the specified company
   * @param companyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyUsers(companyId: string, observe?: "body", reportProgress?: boolean): Observable<Array<User>>;
  public getCompanyUsers(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<User>>>;
  public getCompanyUsers(
    companyId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<User>>>;
  public getCompanyUsers(companyId: string, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyUsers.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<User>>(`${this.basePath}/company/${encodeURIComponent(String(companyId))}/user`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Update user
   * update user
   * @param companyId
   * @param userId
   * @param user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompanyUser(
    companyId: string,
    userId: string,
    user: User,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<User>;
  public updateCompanyUser(
    companyId: string,
    userId: string,
    user: User,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public updateCompanyUser(
    companyId: string,
    userId: string,
    user: User,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public updateCompanyUser(
    companyId: string,
    userId: string,
    user: User,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling updateCompanyUser.");
    }

    if (userId === null || userId === undefined) {
      throw new Error("Required parameter userId was null or undefined when calling updateCompanyUser.");
    }

    if (user === null || user === undefined) {
      throw new Error("Required parameter user was null or undefined when calling updateCompanyUser.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<User>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/user/${encodeURIComponent(String(userId))}`,
      user,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
