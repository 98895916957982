/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Price {
  [key: string]: any;

  onDemand?: boolean;
  /**
   * price in cent
   */
  listPrice?: number;
  base?: number;
  baseUnit?: Price.BaseUnitEnum;
  /**
   * uvp in cent
   */
  uvpPrice?: number;
  uvpPriceBase?: number;
  uvpPriceUnit?: Price.UvpPriceUnitEnum;
}
export namespace Price {
  export type BaseUnitEnum =
    | "CMK"
    | "CMQ"
    | "CMT"
    | "DZN"
    | "GRM"
    | "HLT"
    | "KGM"
    | "KTM"
    | "LTR"
    | "MMT"
    | "MTK"
    | "MTQ"
    | "MTR"
    | "PCE"
    | "PR"
    | "SET"
    | "TNE";
  export const BaseUnitEnum = {
    CMK: "CMK" as BaseUnitEnum,
    CMQ: "CMQ" as BaseUnitEnum,
    CMT: "CMT" as BaseUnitEnum,
    DZN: "DZN" as BaseUnitEnum,
    GRM: "GRM" as BaseUnitEnum,
    HLT: "HLT" as BaseUnitEnum,
    KGM: "KGM" as BaseUnitEnum,
    KTM: "KTM" as BaseUnitEnum,
    LTR: "LTR" as BaseUnitEnum,
    MMT: "MMT" as BaseUnitEnum,
    MTK: "MTK" as BaseUnitEnum,
    MTQ: "MTQ" as BaseUnitEnum,
    MTR: "MTR" as BaseUnitEnum,
    PCE: "PCE" as BaseUnitEnum,
    PR: "PR" as BaseUnitEnum,
    SET: "SET" as BaseUnitEnum,
    TNE: "TNE" as BaseUnitEnum,
  };
  export type UvpPriceUnitEnum =
    | "CMK"
    | "CMQ"
    | "CMT"
    | "DZN"
    | "GRM"
    | "HLT"
    | "KGM"
    | "KTM"
    | "LTR"
    | "MMT"
    | "MTK"
    | "MTQ"
    | "MTR"
    | "PCE"
    | "PR"
    | "SET"
    | "TNE";
  export const UvpPriceUnitEnum = {
    CMK: "CMK" as UvpPriceUnitEnum,
    CMQ: "CMQ" as UvpPriceUnitEnum,
    CMT: "CMT" as UvpPriceUnitEnum,
    DZN: "DZN" as UvpPriceUnitEnum,
    GRM: "GRM" as UvpPriceUnitEnum,
    HLT: "HLT" as UvpPriceUnitEnum,
    KGM: "KGM" as UvpPriceUnitEnum,
    KTM: "KTM" as UvpPriceUnitEnum,
    LTR: "LTR" as UvpPriceUnitEnum,
    MMT: "MMT" as UvpPriceUnitEnum,
    MTK: "MTK" as UvpPriceUnitEnum,
    MTQ: "MTQ" as UvpPriceUnitEnum,
    MTR: "MTR" as UvpPriceUnitEnum,
    PCE: "PCE" as UvpPriceUnitEnum,
    PR: "PR" as UvpPriceUnitEnum,
    SET: "SET" as UvpPriceUnitEnum,
    TNE: "TNE" as UvpPriceUnitEnum,
  };
}
