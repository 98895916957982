/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ETIMTranslation } from "./eTIMTranslation";

export interface ETIMFeature {
  [key: string]: any;

  code?: string;
  type?: ETIMFeature.TypeEnum;
  translation?: ETIMTranslation;
}
export namespace ETIMFeature {
  export type TypeEnum = "Logical" | "Alphanumeric" | "Numeric" | "Range";
  export const TypeEnum = {
    Logical: "Logical" as TypeEnum,
    Alphanumeric: "Alphanumeric" as TypeEnum,
    Numeric: "Numeric" as TypeEnum,
    Range: "Range" as TypeEnum,
  };
}
