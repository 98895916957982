/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Postal address
 */
export interface Address {
  [key: string]: any;

  /**
   * name of person or name of address
   */
  name?: string;
  /**
   * street and number
   */
  street?: string;
  /**
   * comment
   */
  comment?: string;
  /**
   * zip code
   */
  zip?: string;
  city?: string;
  /**
   * gps latitude
   */
  lat: number;
  /**
   * gps longitude
   */
  long: number;
}
