import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { interval } from "rxjs";
import { DataService } from "./data.service";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private timer;
  private tokenRefreshInterval = 240;
  public error: object;
  private scopes: Array<string> = [];

  constructor(private oauthService: OAuthService, private router: Router, private dataService: DataService) {
    this.oauthService.tokenEndpoint = environment.tokenEndpoint;
    this.oauthService.userinfoEndpoint = environment.userInfoEndpoint;
    this.oauthService.clientId = "webapp";
    this.oauthService.scope = "offline_access";
    this.oauthService.oidc = false;
    this.oauthService.showDebugInformation = true;
  }

  /**
   * Initialize Service and try to refresh token.
   */
  public async init() {
    console.log("AuthService: init");
    await this.tryRefreshToken();
  }

  public isAuthenticated(): boolean {
    console.log("AuthService: isAuthenticated: ", this.oauthService.hasValidAccessToken());
    return this.oauthService.hasValidAccessToken();
  }

  public isAdmin(): boolean {
    //console.log('isAdmin:', scopes.admin_crud);
    return (this.oauthService.getGrantedScopes() as Array<string>).includes("admin_crud");
  }

  public isGranted(scope: string): boolean {
    return (this.oauthService.getGrantedScopes() as Array<string>).includes(scope);
  }

  /**
   * Try token refresh and navigate the user accordingly. Initialize Token refresh timer.
   *
   * @param navigate If the user should be navigated after the token refresh.
   */
  public async tryRefreshToken() {
    console.log("AuthService: tryRefreshToken");
    try {
      await this.oauthService.refreshToken();
      await this.initUserData();
      await this.initTokenRefreshTimer();
    } catch (e) {
      console.log(e);
      if (e.status === 400 || e.status === 401 || e.status === 403) {
        this.oauthService.logOut();
      } else if (e.status === 500) {
      } else {
      }
    }
  }

  /**
   * Setup the token refresh timer with tokenRefreshInterval
   */
  public async initTokenRefreshTimer(): Promise<void> {
    if (!this.timer) {
      console.log("AuthService: initTokenRefreshTimer");
      this.timer = interval(this.tokenRefreshInterval * 1000).subscribe(() => {
        //console.log('AuthService: tokenRefreshTimer tick');
        this.tryRefreshToken();
      });
    }
  }

  /**
   * Stop the token refresh timer.
   */
  public async stopTokenRefreshTimer() {
    console.log("AuthService: stopTokenRefreshTimer");
    if (this.timer) {
      await this.timer.unsubscribe();
    }
    this.timer = null;
  }

  /**
   * Try login with the OAuthService and navigate accordingly.
   *
   * @param user
   * @param pw
   */
  public async login(user: string = "user@example.com", pw: string = "user@example.com") {
    console.log("AuthService: login");
    try {
      this.error = null;
      await this.oauthService.fetchTokenUsingPasswordFlow(user, pw);
      await this.initUserData();

      if (this.dataService.userClaims) {
        this.initTokenRefreshTimer();
        console.log("AuthService LOGGED IN:", this.dataService.userClaims);
        if ((this.oauthService.getGrantedScopes() as unknown as string).split(",").includes("admin_crud"))
          await this.router.navigate(["admin"]);
        else await this.router.navigate([""]);
      } else {
        console.log("AuthService ERROR: No user claims");
        // await this.notificationService.showError('No user claims');
      }
    } catch (e) {
      this.error = e;
      console.log(e);
    }
  }

  /**
   * Initialize data by getting the user profile from OAuthSerice and then fetch company and config data from API
   */
  public async initUserData() {
    let profile: any = await this.oauthService.loadUserProfile();
    this.dataService.userClaims = await this.oauthService.getIdentityClaims();
    await this.dataService.getCompany(true);
    await this.dataService.getConfig(true);
    await this.dataService.getCredentials(true);
    Sentry.setUser({
      id: profile.info?.sub,
      email: profile.info?.email,
    });
    console.log("AuthService: initUserData", profile);
    console.log("AuthService: grantedScopes", this.oauthService.getGrantedScopes());
  }

  public isAccountEnabled(): boolean {
    //console.log('AuthService: isAccountEnabled: ', this.dataService.company.expressDeliveryAccount.enabled);
    return this.dataService.company?.expressDeliveryAccount?.enabled;
  }

  public isAccountCredentialsReady(): boolean {
    //console.log('AuthService: isAccountCredentialsReady: ', this.dataService.credentials);
    return this.dataService.credentials?.length > 0;
  }

  public isAccountRequested(): boolean {
    //console.log('AuthService: isAccountRequested: ', this.dataService.company.expressDeliveryAccount.backendCreationRequested);
    return this.dataService.company?.expressDeliveryAccount?.backendCreationRequested;
  }

  /**
   * Logout from OAuthService, stop the token refresh and navigate accordingly. Should be combined with deleting data.
   */
  public async logout() {
    console.log("AuthService: logout");
    this.oauthService.logOut();
    this.stopTokenRefreshTimer();
    await this.router.navigate(["/login"]);
  }
}
