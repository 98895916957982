export const environment = {
  name: "Staging",
  production: false,
  appBasePath: "https://app.staging.jes-x.tech",
  apiBasePath: "https://api.staging.jes-x.tech/api/v1",
  passwordResetURL:
    "https://auth.staging.jes-x.tech/auth/realms/jestech/login-actions/reset-credentials?client_id=webapp",
  tokenEndpoint: "https://auth.staging.jes-x.tech/auth/realms/jestech/protocol/openid-connect/token",
  userInfoEndpoint: "https://auth.staging.jes-x.tech/auth/realms/jestech/protocol/openid-connect/userinfo",
  stompBrokerURL: "wss://stomp.staging.jes-x.tech/ws",
  keycloakAdmin: "https://auth.staging.jes-x.tech/auth/admin/master/console/#/realms/jestech/users/",
};
