import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./services/guards/auth.guard";
import { GrantGuard } from "./services/guards/grant.guard";
import { EnabledGuard } from "./services/guards/enabled.guard";
import { DeeplinkCatchComponent } from "./pages/deeplink-catch/deeplink-catch.component";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginComponentModule),
  },
  {
    path: "register",
    loadChildren: () => import("./pages/register/register-page.module").then(m => m.RegisterComponentPageModule),
  },
  {
    path: "register-success",
    loadChildren: () =>
      import("./pages/register-success/register-success.module").then(m => m.RegisterSuccessComponentModule),
  },
  {
    path: "setup",
    loadChildren: () => import("./pages/setup/setup.module").then(m => m.SetupModule),
    canActivate: [AuthGuard, GrantGuard],
    data: { grantRequired: "wholesalecredentials_manage", fallback: "setup-required" },
  },
  {
    path: "setup-required",
    loadChildren: () =>
      import("./pages/setup-required/setup-required.module").then(m => m.SetupRequiredComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: "oauth-setup/:wholesalePartnerId/:companyId/:mode",
    loadChildren: () => import("./pages/oauth-setup/oauth-setup.module").then(m => m.OauthSetupComponentModule),
  },
  {
    path: "admin",
    loadChildren: () => import("./pages/admin/admin.module").then(m => m.AdminModule),
    canActivate: [AuthGuard, GrantGuard],
    data: { grantRequired: "admin_crud" },
  },
  {
    path: "billing-confirm",
    component: DeeplinkCatchComponent,
  },
  {
    path: "oauth-setup/**",
    component: DeeplinkCatchComponent,
  },
  {
    path: "article",
    component: DeeplinkCatchComponent,
  },
  {
    path: "",
    loadChildren: () => import("./pages/user/user.module").then(m => m.UserModule),
    canActivate: [AuthGuard, EnabledGuard],
  },
  { path: "**", redirectTo: "" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
