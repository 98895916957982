import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-deeplink-catch",
  templateUrl: "./deeplink-catch.component.html",
  styleUrls: ["./deeplink-catch.component.scss"],
})
export class DeeplinkCatchComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  getUrl() {
    return `https://eylt.de/eylt-deeplink-catch?url=${encodeURIComponent(window.location.href)}`;
  }
}
