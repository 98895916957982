<nb-card style="min-width: 300px">
    <nb-card-header class="admin-header">
        {{title}}
    </nb-card-header>
    <nb-card-body>
        <p>{{message}}</p>
        <p *ngIf="message2 !== ''">{{message2}}</p>
    </nb-card-body>
    <nb-card-footer>

        <button nbButton (click)="dialog.close(true)"
                fullWidth appearance="filled" size="medium">
            Ok
        </button>

    </nb-card-footer>
</nb-card>
