/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { CompanyWholesaleCredentials } from "../model/companyWholesaleCredentials";
import { StatusModel } from "../model/statusModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class WholesaleCredentialsService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create new wholesale credentials
   * Create new wholesale credentials
   * @param companyId
   * @param credentials
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCompanyWholesaleCredentials(
    companyId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CompanyWholesaleCredentials>;
  public createCompanyWholesaleCredentials(
    companyId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CompanyWholesaleCredentials>>;
  public createCompanyWholesaleCredentials(
    companyId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CompanyWholesaleCredentials>>;
  public createCompanyWholesaleCredentials(
    companyId: string,
    credentials: CompanyWholesaleCredentials,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling createCompanyWholesaleCredentials."
      );
    }

    if (credentials === null || credentials === undefined) {
      throw new Error(
        "Required parameter credentials was null or undefined when calling createCompanyWholesaleCredentials."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<CompanyWholesaleCredentials>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/wholesalecredentials`,
      credentials,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * delete user
   * Delete specified user
   * @param companyId
   * @param credentialId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public deleteCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public deleteCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public deleteCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling deleteCompanyWholesaleCredential."
      );
    }

    if (credentialId === null || credentialId === undefined) {
      throw new Error(
        "Required parameter credentialId was null or undefined when calling deleteCompanyWholesaleCredential."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/octet-stream"];

    return this.httpClient.delete<StatusModel>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/wholesalecredentials/${encodeURIComponent(
        String(credentialId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get wholesale credentials
   * Returns requested wholesale credentials
   * @param companyId
   * @param credentialId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CompanyWholesaleCredentials>;
  public getCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CompanyWholesaleCredentials>>;
  public getCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CompanyWholesaleCredentials>>;
  public getCompanyWholesaleCredential(
    companyId: string,
    credentialId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyWholesaleCredential.");
    }

    if (credentialId === null || credentialId === undefined) {
      throw new Error(
        "Required parameter credentialId was null or undefined when calling getCompanyWholesaleCredential."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CompanyWholesaleCredentials>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/wholesalecredentials/${encodeURIComponent(
        String(credentialId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get wholesale credentials
   * Returns a list of wholesale credentials linked to specifed company
   * @param companyId
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyWholesaleCredentials(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<CompanyWholesaleCredentials>>;
  public getCompanyWholesaleCredentials(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<CompanyWholesaleCredentials>>>;
  public getCompanyWholesaleCredentials(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<CompanyWholesaleCredentials>>>;
  public getCompanyWholesaleCredentials(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling getCompanyWholesaleCredentials."
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CompanyWholesaleCredentials>>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/wholesalecredentials`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update wholesale credentials
   * update wholesale credentials
   * @param companyId
   * @param credentialId
   * @param credentials
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompanyWholesaleCredentials(
    companyId: string,
    credentialId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CompanyWholesaleCredentials>;
  public updateCompanyWholesaleCredentials(
    companyId: string,
    credentialId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CompanyWholesaleCredentials>>;
  public updateCompanyWholesaleCredentials(
    companyId: string,
    credentialId: string,
    credentials: CompanyWholesaleCredentials,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CompanyWholesaleCredentials>>;
  public updateCompanyWholesaleCredentials(
    companyId: string,
    credentialId: string,
    credentials: CompanyWholesaleCredentials,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling updateCompanyWholesaleCredentials."
      );
    }

    if (credentialId === null || credentialId === undefined) {
      throw new Error(
        "Required parameter credentialId was null or undefined when calling updateCompanyWholesaleCredentials."
      );
    }

    if (credentials === null || credentials === undefined) {
      throw new Error(
        "Required parameter credentials was null or undefined when calling updateCompanyWholesaleCredentials."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<CompanyWholesaleCredentials>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/wholesalecredentials/${encodeURIComponent(
        String(credentialId)
      )}`,
      credentials,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
