/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConfigMobileconfig {
  productdetail?: ConfigMobileconfig.ProductdetailEnum;
  showSuppliers?: boolean;
  showWholesalePartners?: boolean;
}
export namespace ConfigMobileconfig {
  export type ProductdetailEnum = "VARIANT_1" | "VARIANT_2";
  export const ProductdetailEnum = {
    _1: "VARIANT_1" as ProductdetailEnum,
    _2: "VARIANT_2" as ProductdetailEnum,
  };
}
