/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { Company } from "../model/company";
import { CreateSepaDDMandataRequest } from "../model/createSepaDDMandataRequest";
import { ExpressDeliveryAccount } from "../model/expressDeliveryAccount";
import { RegisterCompanyRequest } from "../model/registerCompanyRequest";
import { StatusModel } from "../model/statusModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class CompanyService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create new express delivery account request
   * Request a express delivery account request the information will be verified and later enabled
   * @param companyId
   * @param sepa
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCompanyExpressDeliveryAccount(
    companyId: string,
    sepa: CreateSepaDDMandataRequest,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ExpressDeliveryAccount>;
  public createCompanyExpressDeliveryAccount(
    companyId: string,
    sepa: CreateSepaDDMandataRequest,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ExpressDeliveryAccount>>;
  public createCompanyExpressDeliveryAccount(
    companyId: string,
    sepa: CreateSepaDDMandataRequest,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ExpressDeliveryAccount>>;
  public createCompanyExpressDeliveryAccount(
    companyId: string,
    sepa: CreateSepaDDMandataRequest,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling createCompanyExpressDeliveryAccount."
      );
    }

    if (sepa === null || sepa === undefined) {
      throw new Error(
        "Required parameter sepa was null or undefined when calling createCompanyExpressDeliveryAccount."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<ExpressDeliveryAccount>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/expressdeliveryaccount`,
      sepa,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get list of available companies
   * Returns a list of companies the user has access to
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanies(
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Company>>;
  public getCompanies(
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Company>>>;
  public getCompanies(
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Company>>>;
  public getCompanies(
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Company>>(`${this.basePath}/company`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get company
   * Returns the requested company
   * @param companyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompany(companyId: string, observe?: "body", reportProgress?: boolean): Observable<Company>;
  public getCompany(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Company>>;
  public getCompany(companyId: string, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<Company>>;
  public getCompany(companyId: string, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompany.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Company>(`${this.basePath}/company/${encodeURIComponent(String(companyId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get express delivery account
   * Returns the current express delviery account information
   * @param companyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyExpressDeliveryAccount(
    companyId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ExpressDeliveryAccount>;
  public getCompanyExpressDeliveryAccount(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ExpressDeliveryAccount>>;
  public getCompanyExpressDeliveryAccount(
    companyId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ExpressDeliveryAccount>>;
  public getCompanyExpressDeliveryAccount(
    companyId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling getCompanyExpressDeliveryAccount."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ExpressDeliveryAccount>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/expressdeliveryaccount`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Register company
   * Create new company
   * @param request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerCompany(
    request: RegisterCompanyRequest,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Company>;
  public registerCompany(
    request: RegisterCompanyRequest,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Company>>;
  public registerCompany(
    request: RegisterCompanyRequest,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Company>>;
  public registerCompany(
    request: RegisterCompanyRequest,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error("Required parameter request was null or undefined when calling registerCompany.");
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<Company>(`${this.basePath}/company`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
