/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { ExpressDelivery } from "../model/expressDelivery";
import { Order } from "../model/order";
import { StatusModel } from "../model/statusModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class OrderService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * get order
   * Returns requested order
   * @param companyId
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyOrder(
    companyId: string,
    orderId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Order>;
  public getCompanyOrder(
    companyId: string,
    orderId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Order>>;
  public getCompanyOrder(
    companyId: string,
    orderId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Order>>;
  public getCompanyOrder(
    companyId: string,
    orderId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyOrder.");
    }

    if (orderId === null || orderId === undefined) {
      throw new Error("Required parameter orderId was null or undefined when calling getCompanyOrder.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Order>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/order/${encodeURIComponent(String(orderId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get express delivery
   * Returns delivery status for requested order
   * @param companyId
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyOrderDeliveryInfo(
    companyId: string,
    orderId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ExpressDelivery>;
  public getCompanyOrderDeliveryInfo(
    companyId: string,
    orderId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ExpressDelivery>>;
  public getCompanyOrderDeliveryInfo(
    companyId: string,
    orderId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ExpressDelivery>>;
  public getCompanyOrderDeliveryInfo(
    companyId: string,
    orderId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyOrderDeliveryInfo.");
    }

    if (orderId === null || orderId === undefined) {
      throw new Error("Required parameter orderId was null or undefined when calling getCompanyOrderDeliveryInfo.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ExpressDelivery>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/order/${encodeURIComponent(
        String(orderId)
      )}/delivery`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get list of users
   * Returns a list orders linked to the specified company
   * @param companyId
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyOrders(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Order>>;
  public getCompanyOrders(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Order>>>;
  public getCompanyOrders(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Order>>>;
  public getCompanyOrders(
    companyId: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyOrders.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Order>>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/order`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Submit new order
   * Create new user for company
   * @param companyId
   * @param order
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitOrder(companyId: string, order: Order, observe?: "body", reportProgress?: boolean): Observable<Order>;
  public submitOrder(
    companyId: string,
    order: Order,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Order>>;
  public submitOrder(
    companyId: string,
    order: Order,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Order>>;
  public submitOrder(
    companyId: string,
    order: Order,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling submitOrder.");
    }

    if (order === null || order === undefined) {
      throw new Error("Required parameter order was null or undefined when calling submitOrder.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<Order>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/order`,
      order,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
