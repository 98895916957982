/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { StatusModel } from "../model/statusModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class SupportService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create new support ticket
   * Create new support ticket
   * @param companyId
   * @param subject
   * @param message
   * @param orderId
   * @param articleId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createSupportTicket(
    companyId: string,
    subject: string,
    message: string,
    orderId?: string,
    articleId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public createSupportTicket(
    companyId: string,
    subject: string,
    message: string,
    orderId?: string,
    articleId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public createSupportTicket(
    companyId: string,
    subject: string,
    message: string,
    orderId?: string,
    articleId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public createSupportTicket(
    companyId: string,
    subject: string,
    message: string,
    orderId?: string,
    articleId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling createSupportTicket.");
    }

    if (subject === null || subject === undefined) {
      throw new Error("Required parameter subject was null or undefined when calling createSupportTicket.");
    }

    if (message === null || message === undefined) {
      throw new Error("Required parameter message was null or undefined when calling createSupportTicket.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/x-www-form-urlencoded"];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (subject !== undefined) {
      formParams = formParams.append("subject", <any>subject) || formParams;
    }
    if (message !== undefined) {
      formParams = formParams.append("message", <any>message) || formParams;
    }
    if (orderId !== undefined) {
      formParams = formParams.append("orderId", <any>orderId) || formParams;
    }
    if (articleId !== undefined) {
      formParams = formParams.append("articleId", <any>articleId) || formParams;
    }

    return this.httpClient.post<StatusModel>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/support`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
