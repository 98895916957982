/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Measurement } from "./measurement";

export interface PackageUnit {
  [key: string]: any;

  measurement?: Measurement;
  gtin?: string;
  quantity?: number;
  kind?: PackageUnit.KindEnum;
}
export namespace PackageUnit {
  export type KindEnum =
    | "BB"
    | "BG"
    | "BH"
    | "BK"
    | "CF"
    | "CG"
    | "CH"
    | "CI"
    | "CT"
    | "PA"
    | "PC"
    | "PG"
    | "PK"
    | "PN"
    | "PU"
    | "RG"
    | "SC"
    | "HP"
    | "TU"
    | "BTL"
    | "BX"
    | "CO"
    | "DY"
    | "STG"
    | "TRO"
    | "PLA"
    | "KAN"
    | "GEB"
    | "PMS";
  export const KindEnum = {
    BB: "BB" as KindEnum,
    BG: "BG" as KindEnum,
    BH: "BH" as KindEnum,
    BK: "BK" as KindEnum,
    CF: "CF" as KindEnum,
    CG: "CG" as KindEnum,
    CH: "CH" as KindEnum,
    CI: "CI" as KindEnum,
    CT: "CT" as KindEnum,
    PA: "PA" as KindEnum,
    PC: "PC" as KindEnum,
    PG: "PG" as KindEnum,
    PK: "PK" as KindEnum,
    PN: "PN" as KindEnum,
    PU: "PU" as KindEnum,
    RG: "RG" as KindEnum,
    SC: "SC" as KindEnum,
    HP: "HP" as KindEnum,
    TU: "TU" as KindEnum,
    BTL: "BTL" as KindEnum,
    BX: "BX" as KindEnum,
    CO: "CO" as KindEnum,
    DY: "DY" as KindEnum,
    STG: "STG" as KindEnum,
    TRO: "TRO" as KindEnum,
    PLA: "PLA" as KindEnum,
    KAN: "KAN" as KindEnum,
    GEB: "GEB" as KindEnum,
    PMS: "PMS" as KindEnum,
  };
}
