/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { StatusModel } from "../model/statusModel";
import { Voucher } from "../model/voucher";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class VoucherService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * get not expires vouchers
   * Returns a list with redeemed vouchers
   * @param companyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanyVouchers(companyId: string, observe?: "body", reportProgress?: boolean): Observable<Array<Voucher>>;
  public getCompanyVouchers(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Voucher>>>;
  public getCompanyVouchers(
    companyId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Voucher>>>;
  public getCompanyVouchers(
    companyId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getCompanyVouchers.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Voucher>>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/voucher`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Redeem Voucher
   * redeem voucher
   * @param companyId
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public redeemCompanyVoucher(
    companyId: string,
    code: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Voucher>;
  public redeemCompanyVoucher(
    companyId: string,
    code: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Voucher>>;
  public redeemCompanyVoucher(
    companyId: string,
    code: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Voucher>>;
  public redeemCompanyVoucher(
    companyId: string,
    code: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling redeemCompanyVoucher.");
    }

    if (code === null || code === undefined) {
      throw new Error("Required parameter code was null or undefined when calling redeemCompanyVoucher.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/x-www-form-urlencoded"];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (code !== undefined) {
      formParams = formParams.append("code", <any>code) || formParams;
    }

    return this.httpClient.post<Voucher>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/voucher`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
