/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ArticleAccessory } from "./articleAccessory";
import { ArticleAttribute } from "./articleAttribute";
import { ArticleDocument } from "./articleDocument";
import { ArticlePicture } from "./articlePicture";
import { BonusGroup } from "./bonusGroup";
import { Construction } from "./construction";
import { DiscountGroup } from "./discountGroup";
import { Measurement } from "./measurement";
import { PackageUnit } from "./packageUnit";
import { Price } from "./price";
import { ProductGroup } from "./productGroup";
import { Supplier } from "./supplier";
import { Validity } from "./validity";
import { WareGroup } from "./wareGroup";

export interface Article {
  [key: string]: any;

  id?: string;
  supplierId?: string;
  supplier?: Supplier;
  sku?: string;
  catalogSku?: string;
  gtin?: string;
  supportedDeliveryTypes?: Array<Article.SupportedDeliveryTypesEnum>;
  type?: Article.TypeEnum;
  validity?: Validity;
  shortText1?: string;
  shortText2?: string;
  wareGroupId?: string;
  productGroupId?: string;
  discountGroupId?: string;
  bonusGroupId?: string;
  previewImageUrl?: string;
  largePreviewImageUrl?: string;
  xLargePreviewImageUrl?: string;
  wareGroup?: WareGroup;
  productGroup?: ProductGroup;
  discountGroup?: DiscountGroup;
  bonusGroup?: BonusGroup;
  minOrderAmount?: number;
  minOrderUnit?: Article.MinOrderUnitEnum;
  alternativeArticle?: string;
  successors?: Array<string>;
  url?: string;
  discontinued?: Article.DiscontinuedEnum;
  discontinuedStart?: string;
  construction?: Construction;
  exportabel?: boolean;
  shelfLife?: number;
  measurement?: Measurement;
  deliveryTime?: number;
  series?: string;
  modelNumber?: string;
  descriptionShort?: string;
  descriptionLong?: string;
  descriptionMarketing?: string;
  usageNote?: string;
  price?: Price;
  packageUnits?: Array<PackageUnit>;
  accessories?: Array<ArticleAccessory>;
  pictures?: Array<ArticlePicture>;
  documents?: Array<ArticleDocument>;
  longtext?: string;
  attributes?: Array<ArticleAttribute>;
  wholesalePartners?: Array<string>;
}
export namespace Article {
  export type SupportedDeliveryTypesEnum = "DUMMY" | "GEFOS" | "CLICK_AND_COLLECT";
  export const SupportedDeliveryTypesEnum = {
    DUMMY: "DUMMY" as SupportedDeliveryTypesEnum,
    GEFOS: "GEFOS" as SupportedDeliveryTypesEnum,
    CLICK_AND_COLLECT: "CLICK_AND_COLLECT" as SupportedDeliveryTypesEnum,
  };
  export type TypeEnum =
    | "STD"
    | "ERA"
    | "ERB"
    | "ERC"
    | "VA"
    | "MA"
    | "DLS"
    | "PAK"
    | "SON"
    | "KAL"
    | "STG"
    | "PLA"
    | "GEB";
  export const TypeEnum = {
    STD: "STD" as TypeEnum,
    ERA: "ERA" as TypeEnum,
    ERB: "ERB" as TypeEnum,
    ERC: "ERC" as TypeEnum,
    VA: "VA" as TypeEnum,
    MA: "MA" as TypeEnum,
    DLS: "DLS" as TypeEnum,
    PAK: "PAK" as TypeEnum,
    SON: "SON" as TypeEnum,
    KAL: "KAL" as TypeEnum,
    STG: "STG" as TypeEnum,
    PLA: "PLA" as TypeEnum,
    GEB: "GEB" as TypeEnum,
  };
  export type MinOrderUnitEnum =
    | "CMK"
    | "CMQ"
    | "CMT"
    | "DZN"
    | "GRM"
    | "HLT"
    | "KGM"
    | "KTM"
    | "LTR"
    | "MMT"
    | "MTK"
    | "MTQ"
    | "MTR"
    | "PCE"
    | "PR"
    | "SET"
    | "TNE";
  export const MinOrderUnitEnum = {
    CMK: "CMK" as MinOrderUnitEnum,
    CMQ: "CMQ" as MinOrderUnitEnum,
    CMT: "CMT" as MinOrderUnitEnum,
    DZN: "DZN" as MinOrderUnitEnum,
    GRM: "GRM" as MinOrderUnitEnum,
    HLT: "HLT" as MinOrderUnitEnum,
    KGM: "KGM" as MinOrderUnitEnum,
    KTM: "KTM" as MinOrderUnitEnum,
    LTR: "LTR" as MinOrderUnitEnum,
    MMT: "MMT" as MinOrderUnitEnum,
    MTK: "MTK" as MinOrderUnitEnum,
    MTQ: "MTQ" as MinOrderUnitEnum,
    MTR: "MTR" as MinOrderUnitEnum,
    PCE: "PCE" as MinOrderUnitEnum,
    PR: "PR" as MinOrderUnitEnum,
    SET: "SET" as MinOrderUnitEnum,
    TNE: "TNE" as MinOrderUnitEnum,
  };
  export type DiscontinuedEnum = "No" | "Yes" | "Yes-Successor";
  export const DiscontinuedEnum = {
    No: "No" as DiscontinuedEnum,
    Yes: "Yes" as DiscontinuedEnum,
    Yes_Successor: "Yes-Successor" as DiscontinuedEnum,
  };
}
