/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { CRUDModel } from "../model/cRUDModel";
import { InlineResponse200 } from "../model/inlineResponse200";
import { StatusModel } from "../model/statusModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class AdminService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Delete Entity
   *
   * @param id
   * @param entity
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public _delete(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public _delete(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public _delete(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public _delete(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error("Required parameter id was null or undefined when calling _delete.");
    }

    if (entity === null || entity === undefined) {
      throw new Error("Required parameter entity was null or undefined when calling _delete.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<StatusModel>(
      `${this.basePath}/admin/${encodeURIComponent(String(entity))}/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create Entity
   *
   * @param entity
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public create(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CRUDModel>;
  public create(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CRUDModel>>;
  public create(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CRUDModel>>;
  public create(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error("Required parameter entity was null or undefined when calling create.");
    }

    if (body === null || body === undefined) {
      throw new Error("Required parameter body was null or undefined when calling create.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<CRUDModel>(`${this.basePath}/admin/${encodeURIComponent(String(entity))}`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Entity
   *
   * @param id
   * @param entity
   * @param relations
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public get(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    relations?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CRUDModel>;
  public get(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    relations?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CRUDModel>>;
  public get(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    relations?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CRUDModel>>;
  public get(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    relations?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error("Required parameter id was null or undefined when calling get.");
    }

    if (entity === null || entity === undefined) {
      throw new Error("Required parameter entity was null or undefined when calling get.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (relations) {
      queryParameters = queryParameters.set("relations", relations.join(COLLECTION_FORMATS["csv"]));
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CRUDModel>(
      `${this.basePath}/admin/${encodeURIComponent(String(entity))}/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * List Entities
   *
   * @param entity
   * @param page
   * @param pageSize
   * @param relations
   * @param sort
   * @param query
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    page?: number,
    pageSize?: number,
    relations?: Array<string>,
    sort?: Array<string>,
    query?: CRUDModel,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<InlineResponse200>;
  public list(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    page?: number,
    pageSize?: number,
    relations?: Array<string>,
    sort?: Array<string>,
    query?: CRUDModel,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse200>>;
  public list(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    page?: number,
    pageSize?: number,
    relations?: Array<string>,
    sort?: Array<string>,
    query?: CRUDModel,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse200>>;
  public list(
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    page?: number,
    pageSize?: number,
    relations?: Array<string>,
    sort?: Array<string>,
    query?: CRUDModel,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error("Required parameter entity was null or undefined when calling list.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }
    if (relations) {
      queryParameters = queryParameters.set("relations", relations.join(COLLECTION_FORMATS["csv"]));
    }
    if (sort) {
      queryParameters = queryParameters.set("sort", sort.join(COLLECTION_FORMATS["csv"]));
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<InlineResponse200>(
      `${this.basePath}/admin/${encodeURIComponent(String(entity))}`,
      query,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update Entity
   *
   * @param id
   * @param entity
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public update(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CRUDModel>;
  public update(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CRUDModel>>;
  public update(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CRUDModel>>;
  public update(
    id: string,
    entity:
      | "wholesalepartner"
      | "wholesalebranch"
      | "address"
      | "company"
      | "companywholesalecredentials"
      | "expressdelivery"
      | "expressdeliveryaccount"
      | "expressdeliverystatus"
      | "gefosdispatcher"
      | "order"
      | "wholesaleorder"
      | "basketavailability"
      | "basketbranchavailability"
      | "wholesalearticle"
      | "article"
      | "supplier"
      | "ftpuser"
      | "articlelongtext",
    body: CRUDModel,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error("Required parameter id was null or undefined when calling update.");
    }

    if (entity === null || entity === undefined) {
      throw new Error("Required parameter entity was null or undefined when calling update.");
    }

    if (body === null || body === undefined) {
      throw new Error("Required parameter body was null or undefined when calling update.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.patch<CRUDModel>(
      `${this.basePath}/admin/${encodeURIComponent(String(entity))}/${encodeURIComponent(String(id))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
