import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { filter, map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "../../../services/auth.service";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  public environment = environment;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu = [{ title: "Log out" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public authService: AuthService,
    public dataService: DataService,
    public router: Router
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => (this.currentTheme = themeName));

    this.menuService
      .onItemClick()
      .pipe(
        map(({ item: { title } }) => title),
        takeUntil(this.destroy$)
      )
      .subscribe(title => {
        if (title === "Log out") {
          this.dataService.deleteAll();
          this.authService.logout();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigate([""]);
    return false;
  }

  showArticle(article) {
    this.popovers.toArray().forEach(popover => {
      popover.hide();
    });
    this.router.navigate(["/article/" + article.id]);
  }

  showBasket() {
    this.popovers.toArray().forEach(popover => {
      popover.hide();
    });
    this.router.navigate(["/basket/"]);
  }

  showSetup() {
    this.popovers.toArray().forEach(popover => {
      popover.hide();
    });
    this.router.navigate(["/company/"]);
  }
}
