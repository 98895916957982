/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from "./address";
import { OpeningHours } from "./openingHours";
import { WholesalePartner } from "./wholesalePartner";

/**
 * A branch of a wholesale partner where orders can be picked up
 */
export interface WholesaleBranch {
  [key: string]: any;

  id?: string;
  /**
   * Name of branch. Will be displayed to end user and delivery person
   */
  name?: string;
  /**
   * Adress for pickup
   */
  address?: Address;
  /**
   * hours when pickup is possible
   */
  openingHours?: OpeningHours;
  /**
   * wholesale partner assigned to branch
   */
  wholeSalePartner?: WholesalePartner;
  /**
   * average time in seconds a order needs to be ready for pick up
   */
  pickTimeBuffer?: number;
  deliveryTypes?: Array<WholesaleBranch.DeliveryTypesEnum>;
}
export namespace WholesaleBranch {
  export type DeliveryTypesEnum = "DUMMY" | "GEFOS" | "CLICK_AND_COLLECT";
  export const DeliveryTypesEnum = {
    DUMMY: "DUMMY" as DeliveryTypesEnum,
    GEFOS: "GEFOS" as DeliveryTypesEnum,
    CLICK_AND_COLLECT: "CLICK_AND_COLLECT" as DeliveryTypesEnum,
  };
}
