<nb-layout>
    <nb-layout-column>
        <div class="row justify-content-center align-items-center">
            <div class="col-xxxl-7 col-xxl-8 col-xl-9 col-lg-11 col-md-12">
                <nb-card>
                    <nb-card-body class="py-5 px-3 px-sm-5 px-md-5">
                        <a target="_blank" [href]="getUrl()" nbButton>Einrichtung abschließen</a>
                        <p>Falls der Button nicht funktioniert klicken Sie bitte im Menu auf "..." und dann "Mit App öffnen"</p>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
