/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExpressDelivery } from "./expressDelivery";
import { OrderItems } from "./orderItems";
import { OrderTimes } from "./orderTimes";
import { WholesaleOrder } from "./wholesaleOrder";

export interface Order {
  [key: string]: any;

  id?: string;
  session?: string;
  basketAvailabilityId?: string;
  basketBranchAvailabilityId?: string;
  voucherId?: string;
  phoneNumber?: string;
  deliveryType?: Order.DeliveryTypeEnum;
  status?: Order.StatusEnum;
  type?: Order.TypeEnum;
  /**
   * id of user who submitted order
   */
  userId?: string;
  /**
   * readable id for user
   */
  orderNumber?: string;
  queue?: string;
  orderDate?: Date;
  /**
   * free text field for user for referencing this order
   */
  userReference?: string;
  /**
   * expected times
   */
  expectedTimes?: OrderTimes;
  /**
   * final times
   */
  finalTimes?: OrderTimes;
  wholesaleOrder?: WholesaleOrder;
  expressDelivery?: ExpressDelivery;
  items?: Array<OrderItems>;
}
export namespace Order {
  export type DeliveryTypeEnum = "DUMMY" | "GEFOS" | "UBER" | "CLICK_AND_COLLECT";
  export const DeliveryTypeEnum = {
    DUMMY: "DUMMY" as DeliveryTypeEnum,
    UBER: "UBER" as DeliveryTypeEnum,
    GEFOS: "GEFOS" as DeliveryTypeEnum,
    CLICK_AND_COLLECT: "CLICK_AND_COLLECT" as DeliveryTypeEnum,
  };
  export type StatusEnum =
    | "PENDING"
    | "PENDING_WHOLESALE"
    | "PENDING_DELIVERY"
    | "COMPLETED"
    | "FAILED"
    | "FAILED_WHOLESALE"
    | "FAILED_DELIVERY";
  export const StatusEnum = {
    PENDING: "PENDING" as StatusEnum,
    PENDING_WHOLESALE: "PENDING_WHOLESALE" as StatusEnum,
    PENDING_DELIVERY: "PENDING_DELIVERY" as StatusEnum,
    COMPLETED: "COMPLETED" as StatusEnum,
    FAILED: "FAILED" as StatusEnum,
    FAILED_WHOLESALE: "FAILED_WHOLESALE" as StatusEnum,
    FAILED_DELIVERY: "FAILED_DELIVERY" as StatusEnum,
  };
  export type TypeEnum = "PICKUP" | "EXPRESS_DELIVERY";
  export const TypeEnum = {
    PICKUP: "PICKUP" as TypeEnum,
    EXPRESS_DELIVERY: "EXPRESS_DELIVERY" as TypeEnum,
  };
}
