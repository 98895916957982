/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Voucher {
  id?: string;
  code?: string;
  description?: string;
  orderLimit?: number;
  maxDeliveryCostLimit?: number;
  expiryDate?: string;
  redeemedAt?: string;
  orders?: number;
}
