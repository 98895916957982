/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ERRORUNKNOWN } from "./eRRORUNKNOWN";

export interface User {
  [key: string]: ERRORUNKNOWN | any;

  id?: string;
  username?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  name?: string;
  type?: User.TypeEnum;
}
export namespace User {
  export type TypeEnum = "OWNER" | "ADMIN" | "USER";
  export const TypeEnum = {
    OWNER: "OWNER" as TypeEnum,
    ADMIN: "ADMIN" as TypeEnum,
    USER: "USER" as TypeEnum,
  };
}
