/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Measurement {
  [key: string]: any;

  /**
   * a in mm
   */
  a?: number;
  /**
   * b in mm
   */
  b?: number;
  /**
   * c in mm
   */
  c?: number;
  /**
   * weight in g
   */
  weight?: number;
  amount?: number;
}
