/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArticlePicture {
  [key: string]: any;

  id?: string;
  type?: ArticlePicture.TypeEnum;
  use?: ArticlePicture.UseEnum;
  url?: string;
  previewUrl?: string;
  thumbnailUrl?: string;
  mediumUrl?: string;
  largeUrl?: string;
  hdUrl?: string;
  substitute?: boolean;
  description?: string;
  order?: number;
}
export namespace ArticlePicture {
  export type TypeEnum = "B_" | "S_" | "U_" | "V_" | "X_" | "MI" | "DT" | "KV" | "LS" | "LO" | "KB" | "BF";
  export const TypeEnum = {
    B: "B_" as TypeEnum,
    S: "S_" as TypeEnum,
    U: "U_" as TypeEnum,
    V: "V_" as TypeEnum,
    X: "X_" as TypeEnum,
    MI: "MI" as TypeEnum,
    DT: "DT" as TypeEnum,
    KV: "KV" as TypeEnum,
    LS: "LS" as TypeEnum,
    LO: "LO" as TypeEnum,
    KB: "KB" as TypeEnum,
    BF: "BF" as TypeEnum,
  };
  export type UseEnum = "Druck" | "Web";
  export const UseEnum = {
    Druck: "Druck" as UseEnum,
    Web: "Web" as UseEnum,
  };
}
