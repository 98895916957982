/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArticleAttribute {
  [key: string]: any;

  attrSystem?: string;
  attrClass?: string;
  attrName?: string;
  attrValue?: string;
  attrValue2?: string;
  attrValue3?: string;
}
