/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { StatusModel } from "../model/statusModel";
import { WholesaleBranch } from "../model/wholesaleBranch";
import { WholesalePartner } from "../model/wholesalePartner";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class WholesaleService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * get wholesale branch
   * Returns requested wholesale branch
   * @param wholesaleBranchId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWholesaleBranch(
    wholesaleBranchId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<WholesaleBranch>;
  public getWholesaleBranch(
    wholesaleBranchId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<WholesaleBranch>>;
  public getWholesaleBranch(
    wholesaleBranchId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<WholesaleBranch>>;
  public getWholesaleBranch(
    wholesaleBranchId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (wholesaleBranchId === null || wholesaleBranchId === undefined) {
      throw new Error("Required parameter wholesaleBranchId was null or undefined when calling getWholesaleBranch.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<WholesaleBranch>(
      `${this.basePath}/wholesalebranch/${encodeURIComponent(String(wholesaleBranchId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get wholesale branches
   * Returns a list of wholesale branches
   * @param showOnlyLinked if set to true the response only contains wholesale branches with linked credentials
   * @param name limit response to wholesale branches with provided name
   * @param wholesaleIds limit response to wholesale branches linked to provided wholesale partners
   * @param lat see radius
   * @param _long see radius
   * @param radius limit response to branches in radius of lat/long
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWholesaleBranches(
    showOnlyLinked?: boolean,
    name?: string,
    wholesaleIds?: Array<string>,
    lat?: number,
    _long?: number,
    radius?: number,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<WholesaleBranch>>;
  public getWholesaleBranches(
    showOnlyLinked?: boolean,
    name?: string,
    wholesaleIds?: Array<string>,
    lat?: number,
    _long?: number,
    radius?: number,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WholesaleBranch>>>;
  public getWholesaleBranches(
    showOnlyLinked?: boolean,
    name?: string,
    wholesaleIds?: Array<string>,
    lat?: number,
    _long?: number,
    radius?: number,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WholesaleBranch>>>;
  public getWholesaleBranches(
    showOnlyLinked?: boolean,
    name?: string,
    wholesaleIds?: Array<string>,
    lat?: number,
    _long?: number,
    radius?: number,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (showOnlyLinked !== undefined && showOnlyLinked !== null) {
      queryParameters = queryParameters.set("showOnlyLinked", <any>showOnlyLinked);
    }
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set("name", <any>name);
    }
    if (wholesaleIds) {
      queryParameters = queryParameters.set("wholesaleIds", wholesaleIds.join(COLLECTION_FORMATS["csv"]));
    }
    if (lat !== undefined && lat !== null) {
      queryParameters = queryParameters.set("lat", <any>lat);
    }
    if (_long !== undefined && _long !== null) {
      queryParameters = queryParameters.set("long", <any>_long);
    }
    if (radius !== undefined && radius !== null) {
      queryParameters = queryParameters.set("radius", <any>radius);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<WholesaleBranch>>(`${this.basePath}/wholesalebranch`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * get wholesale partner
   * Returns requested wholesale partner
   * @param wholesalePartnerId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWholesalePartner(
    wholesalePartnerId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<WholesalePartner>;
  public getWholesalePartner(
    wholesalePartnerId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<WholesalePartner>>;
  public getWholesalePartner(
    wholesalePartnerId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<WholesalePartner>>;
  public getWholesalePartner(
    wholesalePartnerId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (wholesalePartnerId === null || wholesalePartnerId === undefined) {
      throw new Error("Required parameter wholesalePartnerId was null or undefined when calling getWholesalePartner.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<WholesalePartner>(
      `${this.basePath}/wholesale/${encodeURIComponent(String(wholesalePartnerId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * get wholesale partners
   * Returns a list of wholesale partners
   * @param showOnlyLinked if set to true the response only contains wholesale partners with linked credentials
   * @param name limit response to wholesale partners with provided name
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWholesalePartners(
    showOnlyLinked?: boolean,
    name?: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<WholesalePartner>>;
  public getWholesalePartners(
    showOnlyLinked?: boolean,
    name?: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WholesalePartner>>>;
  public getWholesalePartners(
    showOnlyLinked?: boolean,
    name?: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WholesalePartner>>>;
  public getWholesalePartners(
    showOnlyLinked?: boolean,
    name?: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (showOnlyLinked !== undefined && showOnlyLinked !== null) {
      queryParameters = queryParameters.set("showOnlyLinked", <any>showOnlyLinked);
    }
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set("name", <any>name);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<WholesalePartner>>(`${this.basePath}/wholesale`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * wholesale order webhook
   *
   * @param wholesalePartnerId
   * @param wholesaleOrderId
   * @param secret
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public processWholesalePartnerWebhook(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public processWholesalePartnerWebhook(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public processWholesalePartnerWebhook(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public processWholesalePartnerWebhook(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (wholesalePartnerId === null || wholesalePartnerId === undefined) {
      throw new Error(
        "Required parameter wholesalePartnerId was null or undefined when calling processWholesalePartnerWebhook."
      );
    }

    if (wholesaleOrderId === null || wholesaleOrderId === undefined) {
      throw new Error(
        "Required parameter wholesaleOrderId was null or undefined when calling processWholesalePartnerWebhook."
      );
    }

    if (secret === null || secret === undefined) {
      throw new Error("Required parameter secret was null or undefined when calling processWholesalePartnerWebhook.");
    }

    if (status === null || status === undefined) {
      throw new Error("Required parameter status was null or undefined when calling processWholesalePartnerWebhook.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (wholesaleOrderId !== undefined && wholesaleOrderId !== null) {
      queryParameters = queryParameters.set("wholesaleOrderId", <any>wholesaleOrderId);
    }
    if (secret !== undefined && secret !== null) {
      queryParameters = queryParameters.set("secret", <any>secret);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set("status", <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<StatusModel>(
      `${this.basePath}/wholesale/${encodeURIComponent(String(wholesalePartnerId))}/webhook`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * wholesale order webhook
   *
   * @param wholesalePartnerId
   * @param wholesaleOrderId
   * @param secret
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public processWholesalePartnerWebhookPost(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "body",
    reportProgress?: boolean
  ): Observable<StatusModel>;
  public processWholesalePartnerWebhookPost(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<StatusModel>>;
  public processWholesalePartnerWebhookPost(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<StatusModel>>;
  public processWholesalePartnerWebhookPost(
    wholesalePartnerId: string,
    wholesaleOrderId: string,
    secret: string,
    status: "COMPLETED" | "FAILED",
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (wholesalePartnerId === null || wholesalePartnerId === undefined) {
      throw new Error(
        "Required parameter wholesalePartnerId was null or undefined when calling processWholesalePartnerWebhookPost."
      );
    }

    if (wholesaleOrderId === null || wholesaleOrderId === undefined) {
      throw new Error(
        "Required parameter wholesaleOrderId was null or undefined when calling processWholesalePartnerWebhookPost."
      );
    }

    if (secret === null || secret === undefined) {
      throw new Error(
        "Required parameter secret was null or undefined when calling processWholesalePartnerWebhookPost."
      );
    }

    if (status === null || status === undefined) {
      throw new Error(
        "Required parameter status was null or undefined when calling processWholesalePartnerWebhookPost."
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (wholesaleOrderId !== undefined && wholesaleOrderId !== null) {
      queryParameters = queryParameters.set("wholesaleOrderId", <any>wholesaleOrderId);
    }
    if (secret !== undefined && secret !== null) {
      queryParameters = queryParameters.set("secret", <any>secret);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set("status", <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json", "application/octet-stream", "application/x-www-form-urlencoded"];

    return this.httpClient.post<StatusModel>(
      `${this.basePath}/wholesale/${encodeURIComponent(String(wholesalePartnerId))}/webhook`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
