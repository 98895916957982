export * from "./admin.service";
import { AdminService } from "./admin.service";
export * from "./adminSupport.service";
import { AdminSupportService } from "./adminSupport.service";
export * from "./article.service";
import { ArticleService } from "./article.service";
export * from "./basket.service";
import { BasketService } from "./basket.service";
export * from "./company.service";
import { CompanyService } from "./company.service";
export * from "./eTIM.service";
import { ETIMService } from "./eTIM.service";
export * from "./general.service";
import { GeneralService } from "./general.service";
export * from "./order.service";
import { OrderService } from "./order.service";
export * from "./support.service";
import { SupportService } from "./support.service";
export * from "./user.service";
import { UserService } from "./user.service";
export * from "./voucher.service";
import { VoucherService } from "./voucher.service";
export * from "./wholesale.service";
import { WholesaleService } from "./wholesale.service";
export * from "./wholesaleCredentials.service";
import { WholesaleCredentialsService } from "./wholesaleCredentials.service";
export const APIS = [
  AdminService,
  AdminSupportService,
  ArticleService,
  BasketService,
  CompanyService,
  ETIMService,
  GeneralService,
  OrderService,
  SupportService,
  UserService,
  VoucherService,
  WholesaleService,
  WholesaleCredentialsService,
];
