/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BasketBranchAvailability {
  [key: string]: any;

  wholesalePartnerId?: string;
  wholesaleBranchId?: string;
  /**
   * branch status for requested basket
   */
  status?: BasketBranchAvailability.StatusEnum;
  /**
   * exptected delivery time in seconds
   */
  expectedDeliveryTime?: number;
  /**
   * distance to drop off location in meters
   */
  distance?: number;
  /**
   * estimated deliver price in cent. lower range
   */
  costMin?: number;
  /**
   * estimated deliver price in cent. upper range
   */
  costMax?: number;
  supportedDeliveryTypes?: Array<BasketBranchAvailability.SupportedDeliveryTypesEnum>;
}
export namespace BasketBranchAvailability {
  export type StatusEnum = "PENDING_RESPONSE" | "IN_STOCK" | "OUT_OFF_STOCK" | "CLOSED" | "FAILED" | "UNSUPPORTED";
  export const StatusEnum = {
    PENDING_RESPONSE: "PENDING_RESPONSE" as StatusEnum,
    IN_STOCK: "IN_STOCK" as StatusEnum,
    OUT_OFF_STOCK: "OUT_OFF_STOCK" as StatusEnum,
    CLOSED: "CLOSED" as StatusEnum,
    FAILED: "FAILED" as StatusEnum,
    UNSUPPORTED: "UNSUPPORTED" as StatusEnum,
  };
  export type SupportedDeliveryTypesEnum = "DUMMY" | "GEFOS" | "UBER" | "CLICK_AND_COLLECT";
  export const SupportedDeliveryTypesEnum = {
    DUMMY: "DUMMY" as SupportedDeliveryTypesEnum,
    GEFOS: "GEFOS" as SupportedDeliveryTypesEnum,
    UBER: "UBER" as SupportedDeliveryTypesEnum,
    CLICK_AND_COLLECT: "CLICK_AND_COLLECT" as SupportedDeliveryTypesEnum,
  };
}
