/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from "./address";
import { BasketItems } from "./basketItems";

export interface Basket {
  [key: string]: any;

  session?: string;
  /**
   * request availability infos only from specified wholesale branch
   */
  branchId?: string;
  /**
   * EXACT can only be used when branchId is set
   */
  mode?: Basket.ModeEnum;
  dropOffAddress: Address;
  items: Array<BasketItems>;
}
export namespace Basket {
  export type ModeEnum = "FAST" | "EXACT";
  export const ModeEnum = {
    FAST: "FAST" as ModeEnum,
    EXACT: "EXACT" as ModeEnum,
  };
}
