/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Each company needs to enter its wholesale credentials
 */
export interface CompanyWholesaleCredentials {
  [key: string]: any;

  id?: string;
  wholesalePartnerId: string;
  authorizationCode?: string;
  username?: string;
  customerNumber?: string;
  password?: string;
  error?: string;
}
