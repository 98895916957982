import { Injectable } from "@angular/core";
import { CredentialsComponent } from "../components/credentials/credentials.component";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { AlertErrorComponent } from "../components/alert-error/alert-error.component";
import { AlertSuccessComponent } from "../components/alert-success/alert-success.component";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import * as Sentry from "@sentry/angular";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public debugMode = false;

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private router: Router,
    private authService: AuthService
  ) {}

  public async showError(e) {
    Sentry.captureException(this.extractError(e));
    console.log("showError");
    let msg: string;
    let msg2: string;
    let title: string = "Fehler";
    if (e.error && e.error.error_description) {
      msg = e.error.error_description;
    } else if (e.error && e.error.message) {
      msg = e.error.message;
      if (e.error.details) {
        msg2 = JSON.stringify(e.error.details);
      }
    } else if (e.message) {
      msg = e.message;
    } else {
      msg = e.toString();
    }

    if (this.debugMode) {
    }

    if (!this.authService.isAdmin()) {
      title = "Ups!";
      msg = "Da ist wohl etwas schief gelaufen.";
      msg2 = "Bitte versuchen Sie es erneut.";
    }

    this.dialogService
      .open(AlertErrorComponent, {
        context: { title: title, message: msg, message2: msg2 },
      })
      .onClose.subscribe(update => {});
  }

  private extractError(error: any) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }
    // We can handle messages and Error objects directly.
    if (typeof error === "string" || error instanceof Error) {
      return error;
    }
    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }
      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }
      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }
      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // ***** CUSTOM *****
    // The above code doesn't always work since 'instanceof' relies on the object being created with the 'new' keyword
    if (error.error && error.error.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }
    // ***** END CUSTOM *****

    // Skip if there's no error, and let user decide what to do with it.
    return error;
  }

  public showSuccess(msg: string, redirectURI: string = null, redirectParams: object = undefined) {
    this.dialogService
      .open(AlertSuccessComponent, {
        context: { message: msg },
      })
      .onClose.subscribe(update => {
        if (redirectURI) {
          this.router.navigate([redirectURI], { queryParams: redirectParams });
        }
      });
  }

  public showSuccessToast(title: string, msg: string) {
    this.toastrService.show(msg, title, { hasIcon: true, icon: "checkmark-outline", status: "success" });
  }

  public showDangerToast(title: string, msg: string) {
    this.toastrService.show(msg, title, { hasIcon: true, icon: "trash-2-outline", status: "danger" });
  }

  public async showMessage(header?: string, message?: string) {
    /*const alert = await this.alerts.create({
          header,
          message,
          buttons: [
           {
              text: 'Okay',
              handler: () => {
                console.log('Confirm Okay');
              }
            }
          ]
        });

        await alert.present();
        const {role} = await alert.onDidDismiss();
        //console.log('onDidDismiss resolved with role', role);*/
  }
}
