import { NgModule, ModuleWithProviders, SkipSelf, Optional } from "@angular/core";
import { Configuration } from "./configuration";
import { HttpClient } from "@angular/common/http";

import { AdminService } from "./api/admin.service";
import { AdminSupportService } from "./api/adminSupport.service";
import { ArticleService } from "./api/article.service";
import { BasketService } from "./api/basket.service";
import { CompanyService } from "./api/company.service";
import { ETIMService } from "./api/eTIM.service";
import { GeneralService } from "./api/general.service";
import { OrderService } from "./api/order.service";
import { SupportService } from "./api/support.service";
import { UserService } from "./api/user.service";
import { VoucherService } from "./api/voucher.service";
import { WholesaleService } from "./api/wholesale.service";
import { WholesaleCredentialsService } from "./api/wholesaleCredentials.service";

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AdminService,
    AdminSupportService,
    ArticleService,
    BasketService,
    CompanyService,
    ETIMService,
    GeneralService,
    OrderService,
    SupportService,
    UserService,
    VoucherService,
    WholesaleService,
    WholesaleCredentialsService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error("ApiModule is already loaded. Import in your base AppModule only.");
    }
    if (!http) {
      throw new Error(
        "You need to import the HttpClientModule in your AppModule! \n" +
          "See also https://github.com/angular/angular/issues/20575"
      );
    }
  }
}
