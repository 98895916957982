<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="/assets/eylt-logo-green-512.png" height="36px" style="margin-top: 6px"/>
    </a>

  </div>

</div>

<div class="header-container">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline" (click)="authService.logout()"></nb-action>-->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user class="white" [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="dataService.userClaims?.preferred_username"
               [title]="environment.name.toUpperCase()"
               [picture]="'/assets/App-User-Webapp.png'">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
