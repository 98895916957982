/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { WholesaleBranch } from "./wholesaleBranch";

export interface WholesaleOrder {
  [key: string]: any;

  id?: string;
  wholesaleBranchId?: string;
  wholesaleBranch?: WholesaleBranch;
  /**
   * order number assigned by wholesale partner
   */
  orderNumber?: string;
  errorMessage?: string;
  secret?: string;
}
