<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">
            <img src="/assets/eylt-logo-green-512.png" height="36px" style="margin-top: 6px"/>
        </a>
    </div>

</div>

<div class="header-container">
    <nb-actions size="small">

        <!-- <nb-action class="control-item">
           <nb-search type="rotate-layout"></nb-search>
         </nb-action>
         <nb-action class="control-item" icon="email-outline"></nb-action>
         <nb-action class="control-item" icon="bell-outline" (click)="authService.logout()"></nb-action>-->
        <nb-action *ngIf="!authService.isAccountEnabled() && !authService.isAccountRequested()"
                   class="control-item"
                   icon="bell-outline"
                   [badgeText]="'1'"
                   badgeStatus="danger"
                   [nbPopover]="notification1">

        </nb-action>

        <nb-action *ngIf="!authService.isAccountEnabled() && authService.isAccountRequested()"
                   class="control-item"
                   icon="bell-outline"
                   [badgeText]="'1'"
                   badgeStatus="warning"
                   [nbPopover]="notification2">

        </nb-action>


        <nb-action class="control-item"
                   icon="star-outline"
                   [nbPopover]="favs">
        </nb-action>
        <nb-action class="control-item"
                   icon="shopping-cart-outline"
                   [badgeText]="dataService.basketQuantity()"
                   badgeStatus="a"
                   badgePosition="top right"
                   [nbPopover]="basket">

        </nb-action>
        <nb-action class="user-action">
            <nb-user class="white" [nbContextMenu]="userMenu"
                     [onlyPicture]="userPictureOnly"
                     [name]="dataService.userClaims?.preferred_username"
                     [title]="environment.name !== 'Production' ? environment.name.toUpperCase() : undefined"
                     [picture]="'/assets/App-User-Webapp.png'">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>

<ng-template #notification1>
    <div style="width: 420px">
        <div style="display: flex; align-items: center; margin: 1rem; cursor: pointer" (click)="showSetup()">
            <div>
                <nb-icon icon="alert-triangle-outline" status="danger" style="font-size: 2rem; margin-right: 1rem"></nb-icon>
            </div>

            <div>
                <h6 style="font-size: 0.9rem;margin:0px;">Keine Einzugsermächtigung</h6>
                <p class="grey" style="overflow-x: auto; white-space: normal; margin: 0">Um den EYLT EXPRESS Service nutzen zu können benötigen wir eine Einzugsermächtigung für unseren Logistikpartner Taxi Deutschland. Bitte in den Einstellungen konfigurieren.</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notification2>
    <div style="width: 420px">
        <div style="display: flex; align-items: center; margin: 1rem; cursor: pointer" (click)="showSetup()">
            <div>
                <nb-icon icon="alert-triangle-outline" status="warning" style="font-size: 2rem; margin-right: 1rem"></nb-icon>
            </div>

            <div>
                <h6 style="font-size: 0.9rem;margin:0px;">Einzugsermächtigung in Bearbeitung</h6>
                <p class="grey" style="overflow-x: auto; white-space: normal; margin: 0;">Ihre Einzugsermächtigung ist in Prüfung. Sie können den EYLT EXPRESS Service nutzen sobald die Prüfung abgeschlossen ist.</p>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #favs>
    <nb-list style="width: 420px" class="admin" *ngIf="dataService.favs && dataService.favs.length > 0">
        <nb-list-item *ngFor="let article of dataService.favs" class="button" (click)="showArticle(article)">
            <div class="col-12" style="display: flex; align-items: center">
                <img *ngIf="article.previewImageUrl" [src]="article.previewImageUrl" class="thumbnail-small"/>
                <div>
                    <h6 style="font-size: 0.9rem;margin:0px;">{{article.shortText1}}</h6>
                    <p class="grey">Werksnr.: {{article.sku}}</p>
                </div>
            </div>
        </nb-list-item>
    </nb-list>
    <nb-list style="width: 420px" class="admin" *ngIf="!dataService.favs || dataService.favs.length <= 0">
        <nb-list-item>
            <div class="col-12" style="display: flex; align-items: center">
                <nb-icon icon="star-outline"></nb-icon>
                <p class="grey" style="margin-left: 0.5rem">Noch keine Favoriten.</p>
            </div>
        </nb-list-item>
    </nb-list>
</ng-template>


<ng-template #basket>
    <nb-list style="width: 420px" class="admin" *ngIf="dataService.basket && dataService.basket.length > 0">
        <nb-list-item *ngFor="let article of dataService.basket" class="button" (click)="showArticle(article)">
            <div class="col-12" style="display: flex; align-items: center">
                <img *ngIf="article.previewImageUrl" [src]="article.previewImageUrl" class="thumbnail-small"/>
                <div>
                    <h6 style="font-size: 0.9rem;margin:0px;">{{article.shortText1}}</h6>
                    <p class="grey">Werksnr.: {{article.sku}}</p>
                </div>
            </div>
        </nb-list-item>
        <nb-list-item>
            <button nbButton (click)="showBasket()"
                    appearance="filled" fullWidth size="medium" status="info">
                Zum Warenkorb
            </button>
        </nb-list-item>
    </nb-list>
    <nb-list style="width: 420px" class="admin" *ngIf="!dataService.basket || dataService.basket.length <= 0">
        <nb-list-item>
            <div class="col-12" style="display: flex; align-items: center">
                <nb-icon icon="shopping-cart-outline"></nb-icon>
                <p class="grey" style="margin-left: 0.5rem">Noch keine Artikel im Warenkorb.</p>
            </div>
        </nb-list-item>
    </nb-list>
</ng-template>