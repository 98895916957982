import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "ngx-alert-error",
  templateUrl: "./alert-error.component.html",
  styleUrls: ["./alert-error.component.scss"],
})
export class AlertErrorComponent implements OnInit {
  @Input()
  public title: string = "Fehler";

  @Input()
  public message: string;

  @Input()
  public message2: string = "";

  constructor(public dialog: NbDialogRef<any>) {}

  ngOnInit() {}
}
