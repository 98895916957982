/**
 * JES Craft App
 * Express delivery
 *
 * Tech Stack: Ionic, Angular, Capacitor
 *
 * Created by Dennis Dörr, November 2021
 * Contact: mail@dennisdoerr.com
 */

import { Injectable } from "@angular/core";
import { Address } from "./model/address";
import { BasketAvailability } from "./model/basketAvailability";
import { Config } from "./model/config";
import { GeneralService } from "./api/general.service";
import { CompanyService } from "./api/company.service";
import { WholesalePartner } from "./model/wholesalePartner";
import { WholesaleService } from "./api/wholesale.service";
import { WholesaleBranch } from "./model/wholesaleBranch";
import { Subject } from "rxjs";
import { Company } from "./model/company";
import { OrderService } from "./api/order.service";
import { Order } from "./model/order";
import { Article } from "./model/article";
import { BasketBranchAvailability } from "./model/basketBranchAvailability";
import SupportedDeliveryTypesEnum = BasketBranchAvailability.SupportedDeliveryTypesEnum;
import { v4 as uuidv4 } from "uuid";
import { ArticleService } from "./api/article.service";
import { Supplier } from "./model/supplier";
import { NbToastRef, NbToastrService } from "@nebular/theme";
import DeliveryTypeEnum = Order.DeliveryTypeEnum;
import { CompanyWholesaleCredentials } from "./model/companyWholesaleCredentials";
import { WholesaleCredentialsService } from "./api/wholesaleCredentials.service";
import { BasketItems } from "./model/basketItems";

/**
 * @name DataService
 * @description This service is responsible for handling, saving and loading the data that is needed for the app to run.
 * If something is not working in this service, the app will probably also not work.
 */
@Injectable({
  providedIn: "root",
})
export class DataService {
  /**
   * Global runtime variables.
   */

  public availability: BasketAvailability;
  public availPending = false;
  public selectedBranchId: string;
  public selectedDeliveryType: DeliveryTypeEnum;

  public config: Config;
  public wholesalers: Array<WholesalePartner>;
  public wholesaleBranches: Array<WholesaleBranch>;
  public suppliers: Array<Supplier>;
  public credentials: Array<CompanyWholesaleCredentials>;
  public userClaims: any;

  public addressIsValid = false;
  public addressValidationErrors: Array<string>;

  public sessionId: string;

  /**
   * Global variables stored in storage
   */
  public basket: Array<Article> = [];
  public favs: Array<Article> = [];
  public last: Array<Article> = [];
  public company: Company;

  public showSuppliers = true;
  public showWholesalers = true;
  public debug = false;

  /**
   * Observables
   */
  public readonly basketChanged: Subject<void> = new Subject<void>();

  /**
   * Private variables
   */
  private address: Address;

  constructor(
    private generalService: GeneralService,
    private companyService: CompanyService,
    private wholesaleService: WholesaleService,
    private orderService: OrderService,
    private articleService: ArticleService,
    private toastrService: NbToastrService,
    private wholesaleCredentialService: WholesaleCredentialsService
  ) {}

  public async init() {
    //this.storage = await this.storageModule.create();
    await this.loadData();
    await this.generateSessionId();
    console.log("DataService:", this);
  }

  /**
   * Load data from storage
   */
  public async loadData(): Promise<any> {
    console.log("DataService: loadData");

    const basket = JSON.parse(localStorage.getItem("basket"));
    this.basket = basket !== null ? basket : [];

    const favs = JSON.parse(localStorage.getItem("favs"));
    this.favs = favs !== null ? favs : [];

    const last = JSON.parse(localStorage.getItem("last"));
    this.last = last !== null ? last : [];

    const address = JSON.parse(localStorage.getItem("address"));
    this.address = address !== null && address !== "" ? address : null;

    /* const last = await this.storage.get('last');
         this.last = last !== null ? last : [];

         const company = await this.storage.get('company');
         this.company = company !== null && company !== '' ? company : null;

         const address = await this.storage.get('address');
         this.address = address !== null && address !== '' ? address : null;

         const showWholesalers = await this.storage.get('showWholesalers');
         this.showWholesalers = showWholesalers !== null ? showWholesalers : this.showWholesalers;

         const showSuppliers = await this.storage.get('showSuppliers');
         this.showSuppliers = showSuppliers !== null ? showSuppliers : this.showSuppliers;

         const debug = await this.storage.get('debug');
         this.debug = debug !== null ? debug : this.debug;
 */
    //const bool = await this.storage.get(key);
    //this.bool = bool !== null ? bool : true;

    //const json = await this.storage.get(key);
    //this.json = json !== null ? JSON.parse(json) : {};

    //console.log('DataService:', this);
  }

  /**
   * Clear all data. Used for logout cleanup.
   */
  public async deleteAll(): Promise<void> {
    console.log("DataService: deleteAll");
    //this.isBrowser = false;

    this.availability = null;
    this.availPending = false;
    this.selectedBranchId = null;
    this.selectedDeliveryType = null;

    this.config = null;
    this.wholesalers = null;
    this.wholesaleBranches = null;
    this.suppliers = null;
    this.userClaims = null;

    this.addressIsValid = false;
    this.addressValidationErrors = null;

    this.basket = [];
    this.favs = [];
    this.last = [];
    this.company = null;
    this.address = null;

    this.debug = false;

    localStorage.clear();
  }

  public generateSessionId() {
    this.sessionId = this.generateUid();
    console.log("generateSessionId", this.sessionId);
  }

  public generateUid(): string {
    return uuidv4();
  }

  public setDebug(value: boolean) {
    this.debug = value;
    //this.storage.set('debug', this.debug);
  }

  /**
   * Sets and stores the address object
   *
   * @param address
   */
  public setAddress(address: Address) {
    this.address = address;
    localStorage.setItem("address", JSON.stringify(this.address));
  }

  /**
   * Get address
   */
  public getAddress(): Address {
    return this.address;
  }

  /**
   * Validate address and cache result in addressIsValid boolean
   */
  public validateAddress(): void {
    if (this.address) {
      const errors: Array<string> = [];
      if (!this.address.street || this.address.street === "") {
        errors.push("Bitte geben Sie eine Straße an.");
      }
      if (!this.address.zip || this.address.zip === "") {
        errors.push("Bitte geben Sie eine Postleitzahl an.");
      }
      if (!this.address.city || this.address.city === "") {
        errors.push("Bitte geben Sie eine Stadt an.");
      }
      if (!this.address.lat || !this.address.long) {
        errors.push("Keine Koordinaten. Bitte erneut Standort festlegen.");
      }
      if (errors.length > 0) {
        this.addressValidationErrors = errors;
        this.addressIsValid = false;
      } else {
        this.addressValidationErrors = null;
        this.addressIsValid = true;
      }
    }
  }

  /**
   * Returns Config object. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getConfig(forceRefresh: boolean = false): Promise<Config> {
    if (this.config && forceRefresh === false) {
      return this.config;
    } else {
      this.config = await this.generalService.getConfig().toPromise();
      return this.config;
    }
  }

  /**
   * Returns Company object. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getCompany(forceRefresh: boolean = false): Promise<Company> {
    if (this.company && forceRefresh === false) {
      return this.company;
    } else {
      const companies = await this.companyService.getCompanies().toPromise();
      this.company = companies[0];
      //await this.storage.set('company', this.company);
      return this.company;
    }
  }

  /**
   * Returns Credetials object. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getCredentials(forceRefresh: boolean = false): Promise<Array<CompanyWholesaleCredentials>> {
    if (this.credentials && forceRefresh === false) {
      return this.credentials;
    } else {
      try {
        this.credentials = await this.wholesaleCredentialService
          .getCompanyWholesaleCredentials(this.company.id)
          .toPromise();
        return this.credentials;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }

  /**
   * Returns Wholsalers Array. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getWholesalers(forceRefresh: boolean = false): Promise<Array<WholesalePartner>> {
    if (this.wholesalers && forceRefresh === false) {
      return this.wholesalers;
    } else {
      try {
        this.wholesalers = await this.wholesaleService.getWholesalePartners().toPromise();
        return this.wholesalers;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }

  /**
   * Returns Wholsaler by id.
   *
   * @param id
   */
  public getWholesalerById(id: string): WholesalePartner {
    return this.wholesalers.find(wholesaler => wholesaler.id === id);
  }

  /**
   * Returns WholsaleBranches Array. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getWholesaleBranches(forceRefresh: boolean = false): Promise<Array<WholesaleBranch>> {
    if (this.wholesaleBranches && forceRefresh === false) {
      return this.wholesaleBranches;
    } else {
      try {
        this.wholesaleBranches = await this.wholesaleService.getWholesaleBranches().toPromise();
        return this.wholesaleBranches;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }

  /**
   * Returns WholsaleBranch by id.
   *
   * @param id
   */
  public getWholesaleBranchById(id: string): WholesaleBranch {
    return this.wholesaleBranches?.find(branch => branch.id === id);
  }

  /**
   * Returns if the provided id is the selected branch for ordering
   *
   * @param id
   */
  public isSelectedBranchId(id: string): boolean {
    return id === this.selectedBranchId;
  }

  /**
   * Selects a branch by id.
   *
   * @param id
   */
  public selectBranchId(id: string) {
    this.selectedBranchId = id;
  }

  /**
   * Returns Suppliers Array. First call or forceRefresh will get data from API.
   *
   * @param forceRefresh
   */
  public async getSuppliers(forceRefresh: boolean = false): Promise<Array<Supplier>> {
    if (this.suppliers && forceRefresh === false) {
      return this.suppliers;
    } else {
      try {
        this.suppliers = await this.articleService.getSuppliers(0, 999).toPromise();
        return this.suppliers;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }

  /**
   * Returns Supplier by id.
   *
   * @param id
   */
  public getSupplierById(id: string): Supplier {
    return this.suppliers.find(suppliers => suppliers.id === id);
  }

  public getRandomSuppliers(n: number = 10): Array<Supplier> {
    const result = new Array(n);
    let len = this.suppliers.length;
    const taken = new Array(len);
    if (n > len) {
      throw new RangeError("getRandom: more elements taken than available");
    }
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = this.suppliers[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  /**
   * Adds a product to the basket.
   *
   * @param product The product object
   * @param quantity Quantity to add. Defaults to one.
   * @param notify Give feedback to the user. Defaults to true.
   */
  public async addToBasket(article: Article, quantity: number = 1, notify: boolean = true) {
    console.log("DataService: addToBasket", article.id);
    const pos = this.basket.findIndex(prod => prod.id === article.id);
    if (pos === -1) {
      article.quantity = quantity;
      this.basket.push(article);
    } else {
      this.basket[pos].quantity = this.basket[pos].quantity + quantity;
    }
    this.basketChanged.next();
    localStorage.setItem("basket", JSON.stringify(this.basket));
    //TODO

    if (notify) {
      this.toastrService.show("Zum Warenkorb hinzugefügt.", "Warenkorb", {
        hasIcon: true,
        icon: "checkmark-outline",
        status: "success",
      });
    }
  }

  /**
   * Remove product quantity from basket.
   *
   * @param product Product object to remove.
   * @param quantity Quantity to remove. Defaults to one.
   */
  public removeFromBasket(article: Article, quantity: number = 1) {
    const pos = this.basket.findIndex(prod => prod.id === article.id);
    if (pos !== -1) {
      console.log("DataService: removeFromBasket", article.id);
      if (this.basket[pos].quantity > 1) {
        this.basket[pos].quantity = this.basket[pos].quantity - quantity;
      } else {
        this.basket.splice(pos, 1);
      }
      this.basketChanged.next();
      localStorage.setItem("basket", JSON.stringify(this.basket));
    }
  }

  /**
   * Delete product from basket without handling quantity.
   *
   * @param product
   */
  public deleteFromBasket(article: Article) {
    const pos = this.basket.findIndex(prod => prod.id === article.id);
    if (pos !== -1) {
      console.log("DataService: deleteFromBasket", article.id);
      this.basket.splice(pos, 1);
      this.basketChanged.next();
      localStorage.setItem("basket", JSON.stringify(this.basket));
    }
  }

  /**
   * Clear Basket.
   *
   */
  public clearBasket() {
    this.basket.length = 0;
    this.basketChanged.next();
    localStorage.setItem("basket", JSON.stringify(this.basket));
  }

  /**
   * Calculate basket overall item quantity
   */
  public basketQuantity(): number {
    if (this.basket) {
      let quantity = 0;
      for (const item of this.basket) {
        quantity += item.quantity;
      }
      return quantity;
    } else {
      return null;
    }
  }

  /**
   * Check if supplied product is in favorites array.
   *
   * @param product
   */
  public isBasket(article: Article): boolean {
    if (article && this.basket && this.basket.findIndex(prod => prod.id === article.id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Add product to favorites.
   *
   * @param product Product object.
   */
  public async addToFavs(article: Article, notify: boolean = true) {
    // console.log('DataService: addToFavs', article.id);
    const pos = this.favs.findIndex(prod => prod.id === article.id);
    console.log("DataService: addToFavs", article.id, pos);
    if (pos === -1) {
      this.favs.push(article);
      localStorage.setItem("favs", JSON.stringify(this.favs));

      if (notify) {
        this.toastrService.show("Zu Favoriten hinzugefügt.", "Favoriten", {
          hasIcon: true,
          icon: "checkmark-outline",
          status: "success",
        });
      }
    }
  }

  /**
   * Remove product from favorites.
   *
   * @param product Product object.
   */
  public removeFromFavs(article: Article) {
    const deletePos = this.favs.findIndex(prod => prod.id === article.id);
    if (deletePos !== -1) {
      console.log("DataService: removeFromFavs", article.id);
      this.favs.splice(deletePos, 1);
      localStorage.setItem("favs", JSON.stringify(this.favs));
    }
  }

  /**
   * Check if supplied product is in favorites array.
   *
   * @param product
   */
  public isFav(article: Article): boolean {
    if (article && this.favs && this.favs.findIndex(prod => prod.id === article.id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Toggle product from favorites.
   *
   * @param product Product object.
   */
  public toggleFav(article: Article) {
    if (this.isFav(article)) {
      this.removeFromFavs(article);
    } else {
      this.addToFavs(article);
    }
  }

  /**
   * Add product to history.
   *
   * @param product Product object.
   */
  public addToLast(article: Article) {
    console.log("DataService: addToLast", article.id);
    const pos = this.last.findIndex(prod => prod.id === article.id);
    if (pos === -1) {
      this.last.unshift(article);
      if (this.last.length > 10) {
        this.last.pop();
      }
    } else {
      this.last.splice(0, 0, this.last.splice(pos, 1)[0]);
    }
    localStorage.setItem("last", JSON.stringify(this.last));
  }

  /**
   * Remove product to favorites.
   *
   * @param product Product object.
   */
  public removeFromLast(article: Article) {
    const deletePos = this.last.findIndex(prod => prod.id === article.id);
    if (deletePos !== -1) {
      console.log("DataService: removeFromLast", article.id);
      this.last.splice(deletePos, 1);
      localStorage.setItem("last", JSON.stringify(this.last));
    }
  }

  /**
   * Get Article Array for Order.
   *
   * @param order Order object.
   */
  public async getArticlesForOrder(order: Order, companyId: string): Promise<Array<Article>> {
    let articles: Array<Article> = [];
    if (order && order.items) {
      for (const item of order.items) {
        try {
          const article: Article = await this.articleService.getArticle(companyId, item.id, ["*"]).toPromise();
          article.quantity = item.quantity;
          article.wholesaleSku = (<Article>item).wholesaleSku;
          articles.push(article);
        } catch (e) {
          console.log(e);
        }
      }
      return articles;
    }
  }

  /**
   * Send order to API.
   * Todo: Will probably get its own service.
   *
   */
  public async sendOrder(
    userReference: string = undefined,
    userComment: string = undefined,
    phoneNumber: string = undefined
  ): Promise<any> {
    const orderRequest: Order = {
      deliveryType: this.selectedDeliveryType,
      basketBranchAvailabilityId: this.availability.id,
      userReference: userReference || undefined,
      phoneNumber: phoneNumber || undefined,
      session: this.sessionId,
      expressDelivery: {
        dropOffAddress: this.address,
        deliveryNote: userComment || undefined,
      },
      wholesaleOrder: {
        wholesaleBranchId: this.selectedBranchId,
      },
      items: this.basket,
    };
    console.log("DataService sendOrder:", orderRequest);
    try {
      const order: Order = await this.orderService.submitOrder(this.company.id, orderRequest).toPromise();
      console.log(order);
      if (order && order.status === Order.StatusEnum.PENDING) {
        console.log("DataService order success:", order);
        this.clearBasket();
        this.generateSessionId();
      }
      return order;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
