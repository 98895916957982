/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArticleDocument {
  [key: string]: any;

  id?: string;
  type?: ArticleDocument.TypeEnum;
  url?: string;
  description?: string;
  previewUrl?: string;
  order?: number;
}
export namespace ArticleDocument {
  export type TypeEnum =
    | "2D"
    | "2F"
    | "2S"
    | "3C"
    | "3A"
    | "DB"
    | "GG"
    | "IS"
    | "MA"
    | "TZ"
    | "VI"
    | "WA"
    | "PA"
    | "PP"
    | "ZL"
    | "SB"
    | "SF"
    | "PF"
    | "LE"
    | "EL"
    | "UP"
    | "WL"
    | "WM"
    | "VT"
    | "EP"
    | "BS"
    | "EX"
    | "AS"
    | "KS"
    | "CE"
    | "VD"
    | "SS"
    | "PL";
  export const TypeEnum = {
    _2D: "2D" as TypeEnum,
    _2F: "2F" as TypeEnum,
    _2S: "2S" as TypeEnum,
    _3C: "3C" as TypeEnum,
    _3A: "3A" as TypeEnum,
    DB: "DB" as TypeEnum,
    GG: "GG" as TypeEnum,
    IS: "IS" as TypeEnum,
    MA: "MA" as TypeEnum,
    TZ: "TZ" as TypeEnum,
    VI: "VI" as TypeEnum,
    WA: "WA" as TypeEnum,
    PA: "PA" as TypeEnum,
    PP: "PP" as TypeEnum,
    ZL: "ZL" as TypeEnum,
    SB: "SB" as TypeEnum,
    SF: "SF" as TypeEnum,
    PF: "PF" as TypeEnum,
    LE: "LE" as TypeEnum,
    EL: "EL" as TypeEnum,
    UP: "UP" as TypeEnum,
    WL: "WL" as TypeEnum,
    WM: "WM" as TypeEnum,
    VT: "VT" as TypeEnum,
    EP: "EP" as TypeEnum,
    BS: "BS" as TypeEnum,
    EX: "EX" as TypeEnum,
    AS: "AS" as TypeEnum,
    KS: "KS" as TypeEnum,
    CE: "CE" as TypeEnum,
    VD: "VD" as TypeEnum,
    SS: "SS" as TypeEnum,
    PL: "PL" as TypeEnum,
  };
}
