/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OpeningHoursHours } from "./openingHoursHours";

export interface OpeningHoursDays {
  day?: OpeningHoursDays.DayEnum;
  hours?: Array<OpeningHoursHours>;
}
export namespace OpeningHoursDays {
  export type DayEnum = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  export const DayEnum = {
    MONDAY: "MONDAY" as DayEnum,
    TUESDAY: "TUESDAY" as DayEnum,
    WEDNESDAY: "WEDNESDAY" as DayEnum,
    THURSDAY: "THURSDAY" as DayEnum,
    FRIDAY: "FRIDAY" as DayEnum,
    SATURDAY: "SATURDAY" as DayEnum,
    SUNDAY: "SUNDAY" as DayEnum,
  };
}
