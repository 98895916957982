import { RouterModule } from "@angular/router"; // we also need angular router for Nebular to function properly
import { NbButtonModule, NbCardModule, NbLayoutModule } from "@nebular/theme";
import { NgModule } from "@angular/core";
import { DeeplinkCatchComponent } from "./deeplink-catch.component";

@NgModule({
  imports: [RouterModule, NbLayoutModule, NbButtonModule, NbCardModule],
  declarations: [DeeplinkCatchComponent],
})
export class DeeplinkCatchModule {}
