/**
 * JES-API
 * Express delivery
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs/Observable";

import { Article } from "../model/article";
import { ArticleSearchResponse } from "../model/articleSearchResponse";
import { BonusGroup } from "../model/bonusGroup";
import { DiscountGroup } from "../model/discountGroup";
import { ProductGroup } from "../model/productGroup";
import { StatusModel } from "../model/statusModel";
import { Supplier } from "../model/supplier";
import { WareGroup } from "../model/wareGroup";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class ArticleService {
  protected basePath = "http://localhost:8080/api/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get Article Details
   *
   * @param companyId
   * @param articleId
   * @param fields
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getArticle(
    companyId: string,
    articleId: string,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
    >,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Article>;
  public getArticle(
    companyId: string,
    articleId: string,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
    >,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Article>>;
  public getArticle(
    companyId: string,
    articleId: string,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
    >,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Article>>;
  public getArticle(
    companyId: string,
    articleId: string,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
    >,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getArticle.");
    }

    if (articleId === null || articleId === undefined) {
      throw new Error("Required parameter articleId was null or undefined when calling getArticle.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (fields) {
      queryParameters = queryParameters.set("fields", fields.join(COLLECTION_FORMATS["csv"]));
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Article>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/article/${encodeURIComponent(
        String(articleId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Articles
   *
   * @param companyId
   * @param page
   * @param pageSize
   * @param query
   * @param supplierId
   * @param waregroupId
   * @param productgroupId
   * @param session
   * @param showOnlyWarehouseArticle
   * @param deliveryTypes
   * @param fields
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getArticles(
    companyId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    supplierId?: string,
    waregroupId?: string,
    productgroupId?: string,
    session?: string,
    showOnlyWarehouseArticle?: boolean,
    deliveryTypes?: Array<"GEFOS" | "CLICK_AND_COLLECT" | "DUMMY">,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
      | "supportedDeliveryTypes"
    >,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ArticleSearchResponse>;
  public getArticles(
    companyId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    supplierId?: string,
    waregroupId?: string,
    productgroupId?: string,
    session?: string,
    showOnlyWarehouseArticle?: boolean,
    deliveryTypes?: Array<"GEFOS" | "CLICK_AND_COLLECT" | "DUMMY">,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
      | "supportedDeliveryTypes"
    >,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ArticleSearchResponse>>;
  public getArticles(
    companyId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    supplierId?: string,
    waregroupId?: string,
    productgroupId?: string,
    session?: string,
    showOnlyWarehouseArticle?: boolean,
    deliveryTypes?: Array<"GEFOS" | "CLICK_AND_COLLECT" | "DUMMY">,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
      | "supportedDeliveryTypes"
    >,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ArticleSearchResponse>>;
  public getArticles(
    companyId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    supplierId?: string,
    waregroupId?: string,
    productgroupId?: string,
    session?: string,
    showOnlyWarehouseArticle?: boolean,
    deliveryTypes?: Array<"GEFOS" | "CLICK_AND_COLLECT" | "DUMMY">,
    fields?: Array<
      | "*"
      | "id"
      | "supplierId"
      | "supplier"
      | "sku"
      | "catalogSku"
      | "gtin"
      | "type"
      | "validity"
      | "shortText1"
      | "shortText2"
      | "wareGroupId"
      | "productGroupId"
      | "discountGroupId"
      | "bonusGroupId"
      | "wareGroup"
      | "productGroup"
      | "discountGroup"
      | "bonusGroup"
      | "minOrderAmount"
      | "minOrderUnit"
      | "alternativeArticle"
      | "successors"
      | "url"
      | "discontinued"
      | "discontinuedStart"
      | "construction"
      | "exportabel"
      | "shelfLife"
      | "measurement"
      | "deliveryTime"
      | "series"
      | "modelNumber"
      | "descriptionShort"
      | "descriptionLong"
      | "descriptionMarketing"
      | "usageNote"
      | "price"
      | "packageUnits"
      | "accessories"
      | "pictures"
      | "documents"
      | "longtext"
      | "attributes"
      | "previewImageUrl"
      | "largePreviewImageUrl"
      | "xLargePreviewImageUrl"
      | "isExpressDeliverySupported"
      | "supportedDeliveryTypes"
    >,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error("Required parameter companyId was null or undefined when calling getArticles.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set("query", <any>query);
    }
    if (supplierId !== undefined && supplierId !== null) {
      queryParameters = queryParameters.set("supplierId", <any>supplierId);
    }
    if (waregroupId !== undefined && waregroupId !== null) {
      queryParameters = queryParameters.set("waregroupId", <any>waregroupId);
    }
    if (productgroupId !== undefined && productgroupId !== null) {
      queryParameters = queryParameters.set("productgroupId", <any>productgroupId);
    }
    if (session !== undefined && session !== null) {
      queryParameters = queryParameters.set("session", <any>session);
    }
    if (showOnlyWarehouseArticle !== undefined && showOnlyWarehouseArticle !== null) {
      queryParameters = queryParameters.set("showOnlyWarehouseArticle", <any>showOnlyWarehouseArticle);
    }
    if (deliveryTypes) {
      queryParameters = queryParameters.set("deliveryTypes", deliveryTypes.join(COLLECTION_FORMATS["csv"]));
    }
    if (fields) {
      queryParameters = queryParameters.set("fields", fields.join(COLLECTION_FORMATS["csv"]));
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ArticleSearchResponse>(
      `${this.basePath}/company/${encodeURIComponent(String(companyId))}/article`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier Details
   *
   * @param supplierId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplier(supplierId: string, observe?: "body", reportProgress?: boolean): Observable<Supplier>;
  public getSupplier(
    supplierId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Supplier>>;
  public getSupplier(supplierId: string, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<Supplier>>;
  public getSupplier(supplierId: string, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplier.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Supplier>(`${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Supplier bonus group
   *
   * @param supplierId
   * @param bonusgroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierBonusGroup(
    supplierId: string,
    bonusgroupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<BonusGroup>;
  public getSupplierBonusGroup(
    supplierId: string,
    bonusgroupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<BonusGroup>>;
  public getSupplierBonusGroup(
    supplierId: string,
    bonusgroupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<BonusGroup>>;
  public getSupplierBonusGroup(
    supplierId: string,
    bonusgroupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierBonusGroup.");
    }

    if (bonusgroupId === null || bonusgroupId === undefined) {
      throw new Error("Required parameter bonusgroupId was null or undefined when calling getSupplierBonusGroup.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<BonusGroup>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/bonusgroup/${encodeURIComponent(
        String(bonusgroupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier bonus groups
   *
   * @param supplierId
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierBonusGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<BonusGroup>>;
  public getSupplierBonusGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<BonusGroup>>>;
  public getSupplierBonusGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<BonusGroup>>>;
  public getSupplierBonusGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierBonusGroups.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<BonusGroup>>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/bonusgroup`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier discount group
   *
   * @param supplierId
   * @param discountgroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierDiscountGroup(
    supplierId: string,
    discountgroupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<DiscountGroup>;
  public getSupplierDiscountGroup(
    supplierId: string,
    discountgroupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<DiscountGroup>>;
  public getSupplierDiscountGroup(
    supplierId: string,
    discountgroupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<DiscountGroup>>;
  public getSupplierDiscountGroup(
    supplierId: string,
    discountgroupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierDiscountGroup.");
    }

    if (discountgroupId === null || discountgroupId === undefined) {
      throw new Error(
        "Required parameter discountgroupId was null or undefined when calling getSupplierDiscountGroup."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<DiscountGroup>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/discountgroup/${encodeURIComponent(
        String(discountgroupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get supplier discount groups
   *
   * @param supplierId
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierDiscountGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<WareGroup>>;
  public getSupplierDiscountGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WareGroup>>>;
  public getSupplierDiscountGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WareGroup>>>;
  public getSupplierDiscountGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierDiscountGroups.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<WareGroup>>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/discountgroup`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier product group
   *
   * @param supplierId
   * @param productgroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierProductGroup(
    supplierId: string,
    productgroupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProductGroup>;
  public getSupplierProductGroup(
    supplierId: string,
    productgroupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductGroup>>;
  public getSupplierProductGroup(
    supplierId: string,
    productgroupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductGroup>>;
  public getSupplierProductGroup(
    supplierId: string,
    productgroupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierProductGroup.");
    }

    if (productgroupId === null || productgroupId === undefined) {
      throw new Error("Required parameter productgroupId was null or undefined when calling getSupplierProductGroup.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProductGroup>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/productgroup/${encodeURIComponent(
        String(productgroupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier product groups
   *
   * @param supplierId
   * @param page
   * @param pageSize
   * @param waregroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierProductGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    waregroupId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ProductGroup>>;
  public getSupplierProductGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    waregroupId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProductGroup>>>;
  public getSupplierProductGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    waregroupId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProductGroup>>>;
  public getSupplierProductGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    waregroupId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierProductGroups.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }
    if (waregroupId !== undefined && waregroupId !== null) {
      queryParameters = queryParameters.set("waregroupId", <any>waregroupId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ProductGroup>>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/productgroup`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier&#39;s ware group
   *
   * @param supplierId
   * @param waregroupId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierWareGroup(
    supplierId: string,
    waregroupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<WareGroup>;
  public getSupplierWareGroup(
    supplierId: string,
    waregroupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<WareGroup>>;
  public getSupplierWareGroup(
    supplierId: string,
    waregroupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<WareGroup>>;
  public getSupplierWareGroup(
    supplierId: string,
    waregroupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierWareGroup.");
    }

    if (waregroupId === null || waregroupId === undefined) {
      throw new Error("Required parameter waregroupId was null or undefined when calling getSupplierWareGroup.");
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<WareGroup>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/waregroup/${encodeURIComponent(
        String(waregroupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplier&#39;s ware groups
   *
   * @param supplierId
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSupplierWareGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<WareGroup>>;
  public getSupplierWareGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<WareGroup>>>;
  public getSupplierWareGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<WareGroup>>>;
  public getSupplierWareGroups(
    supplierId: string,
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (supplierId === null || supplierId === undefined) {
      throw new Error("Required parameter supplierId was null or undefined when calling getSupplierWareGroups.");
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<WareGroup>>(
      `${this.basePath}/supplier/${encodeURIComponent(String(supplierId))}/waregroup`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Supplieres
   *
   * @param page
   * @param pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSuppliers(
    page?: number,
    pageSize?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Supplier>>;
  public getSuppliers(
    page?: number,
    pageSize?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Supplier>>>;
  public getSuppliers(
    page?: number,
    pageSize?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Supplier>>>;
  public getSuppliers(
    page?: number,
    pageSize?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set("page", <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set("pageSize", <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth2) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Supplier>>(`${this.basePath}/supplier`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
